import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { getOrders, IOrder } from '@services/shoppingcardservice'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'

import OrderCard from '@objects/cards/order'

export default function RequestsPage() {
  const intl = useIntl()
  const [ordersList, setOrdersList] = useState<Array<IOrder>>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    updateOrdersList()
  }, [])

  function onActionClick() {
    updateOrdersList()
  }

  function updateOrdersList() {
    setLoading(true)
    getOrders().then((orders) => {
      setOrdersList(orders)
      setLoading(false)
    })
  }

  function renderCards() {
    if (!ordersList?.length) return

    return ordersList.map((order) => (
      <OrderCard key={order.id} order={order} onActionClick={onActionClick} />
    ))
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'page.gdpr.note',
        })}
      />
      <LoadingSkeleton loading={loading} type="card" number={4}>
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}
